import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn";
import Banner from "../images/blog/16_3.png";
import Neurodiversity from "../images/blog/16_2.jpg";
import { PrimaryButton } from "components/misc/Buttons";
import { Link } from "react-router-dom";
const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

const SectionHeading = tw.h2`text-4xl sm:text-4xl font-black tracking-wide text-center`;
const HeadingRow = tw.div`text-center mb-8`;
const Heading = tw(SectionHeading)`text-gray-900 mb-4 text-center`;
const ImgWrap = styled.div`my-10`;
const Image = styled.img`w-full h-auto`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800 mt-16`}
  p {
    ${tw`my-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>The SPELL Approach to Therapeutic Activities</Heading>
            <p>
              February 2023 • written by <strong>Saisri Tangirala</strong>
            </p>
          </HeadingRow>
          <Image src={Banner} />
          <Text>
            <h1>The SPELL Approach</h1>
            <p>
              Children on the spectrum often have different approaches to social
              interaction, communication, and repetitive behaviors, which can
              make it challenging for them to succeed in traditional educational
              and employment settings. However, with the right support and
              intervention, children with autism can develop the skills they
              need to lead fulfilling lives.
            </p>
            <p>
              One approach that has proven effective in supporting children on
              the autism spectrum is the SPELL (Strategic Play and Experiential
              Learning for Leaders) approach. This program, developed by the
              National Autistic Society in the United Kingdom, uses a
              combination of theory, practical activities, and group work to
              help children on the autism spectrum improve their leadership
              skills and employability. AUesome has combined some ways that the
              SPELL approach can be used to support children on the autism
              spectrum:
            </p>
            <ImgWrap>
              <Image src={Neurodiversity} />
            </ImgWrap>
            <p></p>
            <ul>
              <li>
                Provide a supportive environment: Creating a supportive and
                understanding environment is key to helping children on the
                autism spectrum feel safe and confident. This can be achieved by
                using visual aids, having a clear structure for activities, and
                avoiding sudden changes in plans or routines. By creating a
                predictable and supportive environment, children on the autism
                spectrum are able to focus on their learning and development,
                rather than feeling overwhelmed or stressed.
              </li>
              <li>
                Use experiential learning: Experiential learning is an approach
                that uses hands-on activities and real-life scenarios to help
                children on the autism spectrum develop their skills. This
                approach helps children understand the practical applications of
                what they are learning and can make the information more
                memorable. For example, role-playing exercises can be used to
                help children on the autism spectrum practice their social
                skills, while hands-on activities can help them develop their
                problem-solving and critical thinking skills.
              </li>
              <li>
                Focus on strengths: Emphasizing the strengths and abilities of
                children on the autism spectrum is important for building their
                confidence and encouraging them to develop new skills. By
                focusing on what they can do well, rather than their weaknesses,
                children on the autism spectrum are able to see themselves as
                capable and competent individuals. This can help to boost their
                self-esteem and motivation to continue learning and growing.
              </li>
              <li>
                Encourage teamwork: Working in small groups is an important part
                of the SPELL approach, as it helps children on the autism
                spectrum develop their social skills and build relationships
                with others. By working together on projects and activities,
                children on the autism spectrum are able to practice their
                communication and collaboration skills, which can be
                particularly challenging for them. Encouraging children on the
                autism spectrum to work together in a supportive and
                understanding environment can help to build their confidence and
                social skills.
              </li>
              <li>
                Use positive reinforcement: Positive reinforcement is an
                important part of the SPELL approach, as it helps to motivate
                children on the autism spectrum to continue developing their
                skills. This can include verbal praise, stickers, or rewards for
                effort. By recognizing their accomplishments and efforts,
                children on the autism spectrum are able to see that their hard
                work is paying off and that they are making progress. This can
                help to boost their self-esteem and motivation to continue
                learning and growing.
              </li>
            </ul>
            <p>
              The SPELL approach can be a valuable tool for supporting children
              on the autism spectrum. By providing a supportive environment,
              using experiential learning, focusing on strengths, encouraging
              teamwork, and using positive reinforcement, children on the autism
              spectrum can develop the skills they need to lead fulfilling
              lives. It's important to remember that each child on the autism
              spectrum is unique and may respond differently to different
              approaches. However, by being flexible and adapting the SPELL
              approach to meet the needs of each individual child, educators and
              caregivers can help children on the autism spectrum.
            </p>
          </Text>
          <ButtonContainer>
            <LoadMoreButton as="a" href="/blog">
              View All Blogs
            </LoadMoreButton>
          </ButtonContainer>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
