import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn";
import Banner from "../images/blog/17_2.png";
import Neurodiversity from "../images/blog/17_3.jpg";
import { PrimaryButton } from "components/misc/Buttons";
import { Link } from "react-router-dom";
const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

const SectionHeading = tw.h2`text-4xl sm:text-4xl font-black tracking-wide text-center`;
const HeadingRow = tw.div`text-center mb-8`;
const Heading = tw(SectionHeading)`text-gray-900 mb-4 text-center`;
const ImgWrap = styled.div`my-10`;
const Image = styled.img`w-full h-auto`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800 mt-16`}
  p {
    ${tw`my-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>The Nature of Anxiety in Everyday Actions</Heading>
            <p>
              May 2023 • written by <strong>Saisri Tangirala</strong>
            </p>
          </HeadingRow>
          <Image src={Banner} />
          <Text>
            <h1>Anxiety and Neurodiversity</h1>
            <p>
              Anxiety is a common experience for many children and can be even
              more pronounced in children on the autism spectrum. Children with
              autism often face unique challenges that can contribute to
              anxiety, such as difficulty with social interaction, sensory
              sensitivities, and difficulty with change. It's important to
              understand that while anxiety can be distressing, there are
              effective treatments available to help children on the spectrum
              manage their symptoms. Consulting with a mental health
              professional can help assess a child's symptoms, determine the
              specific causes of their anxiety, and develop an effective
              treatment plan.
            </p>
            <p>
              Cognitive-behavioral therapy (CBT) is a commonly used therapy for
              treating anxiety in children on the autism spectrum. CBT helps
              children learn to recognize and manage their anxious thoughts and
              behaviors. It can also teach children coping skills to help them
              manage their anxiety in real-life situations.
            </p>
            <ImgWrap>
              <Image src={Neurodiversity} />
            </ImgWrap>
            <p></p>

            <p>
              In addition to therapy, lifestyle changes can also help reduce
              anxiety symptoms in children with autism. Encouraging regular
              exercise, a healthy diet, and good sleep habits can improve
              overall well-being and reduce anxiety symptoms. Autism-specific
              approaches, such as sensory integration therapy and social skills
              training, can also help address the specific challenges that
              children with autism may face in managing anxiety.
            </p>
            <p>
              Parents play a critical role in helping their child manage
              anxiety. Parent training and support can help parents understand
              the causes of their child's anxiety, recognize triggers, and
              provide a supportive environment. Schools can also help by
              providing a predictable routine, reducing sensory overload, and
              providing support during transitions.
            </p>
            <p>
              Treating anxiety in children on the autism spectrum requires a
              multi-faceted approach. Mental health professionals, therapy,
              medication, lifestyle changes, autism-specific approaches, parent
              training, and school support can all play a role in helping
              children with autism manage their anxiety symptoms. With the right
              resources and support, children with autism can learn to manage
              their anxiety and lead fulfilling, successful lives.
            </p>
          </Text>
          <ButtonContainer>
            <LoadMoreButton as="a" href="/blog">
              View All Blogs
            </LoadMoreButton>
          </ButtonContainer>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
